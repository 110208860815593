import React from 'react'
import { Container } from 'reactstrap'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Link from 'gatsby-link'
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import { FaArrowLeft } from 'react-icons/fa';

class GatsGall extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }
  render() {

    const photos = [];
      /*
      { src: this.props.img2, width: 4, height: 4, alt: this.props.post_title },
      { src: this.props.img3, width: 4, height: 4, alt: this.props.post_title },
      { src: this.props.img4, width: 4, height: 4, alt: this.props.post_title },
      { src: this.props.img5, width: 4, height: 4, alt: this.props.post_title },
      { src: this.props.img6, width: 4, height: 4, alt: this.props.post_title },
      { src: this.props.img7, width: 4, height: 4, alt: this.props.post_title },
      { src: this.props.img8, width: 4, height: 4, alt: this.props.post_title },
      { src: this.props.img9, width: 4, height: 4, alt: this.props.post_title },
    */
    if(this.props.img1) photos.push({ src: this.props.img1, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img2) photos.push({ src: this.props.img2, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img3) photos.push({ src: this.props.img3, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img4) photos.push({ src: this.props.img4, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img5) photos.push({ src: this.props.img5, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img6) photos.push({ src: this.props.img6, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img7) photos.push({ src: this.props.img7, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img8) photos.push({ src: this.props.img8, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img9) photos.push({ src: this.props.img9, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img10) photos.push({ src: this.props.img10, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img11) photos.push({ src: this.props.img11, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img12) photos.push({ src: this.props.img12, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img13) photos.push({ src: this.props.img13, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img14) photos.push({ src: this.props.img14, width: 4, height: 3, alt: this.props.post_title })
    if(this.props.img15) photos.push({ src: this.props.img15, width: 4, height: 3, alt: this.props.post_title })



     
    if(this.props.img1){
    return ( 
      <div className="blogGallery">
      <h3 className="galtitle">Gallery</h3>
        <Gallery photos={photos} direction={'column'} columns={'5'} onClick={this.openLightbox} />
        <Lightbox images={photos}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
      </div>
    ) } else {
      return (null)
    }
  }
}

export default function Template ({ data }) {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <div className="main-content">
        <Helmet title={`${post.frontmatter.title} | Σπιτικό Παπακωνσταντίνου`}>
        </Helmet>
        <Container>
          <h1 className='display-3'>{post.frontmatter.title}</h1>
        </Container>
        <img className="featuredBig" src={post.frontmatter.thumbnail} alt={post.frontmatter.title}></img>
        <Container className="blogContent" dangerouslySetInnerHTML={{ __html: post.html }} />        
      </div>
      <GatsGall 
        img1={post.frontmatter.img1} 
        img2={post.frontmatter.img2} 
        img3={post.frontmatter.img3}
        img4={post.frontmatter.img4}
        img5={post.frontmatter.img5}
        img6={post.frontmatter.img6}
        img7={post.frontmatter.img7}
        img8={post.frontmatter.img8}
        img9={post.frontmatter.img9}
        img10={post.frontmatter.img10}
        img11={post.frontmatter.img11}
        img12={post.frontmatter.img12}
        img13={post.frontmatter.img13}
        img14={post.frontmatter.img14}
        img15={post.frontmatter.img15}
        post_title={post.frontmatter.title}
        />
        <Link to="/ta-nea-mas" className="back-button"><FaArrowLeft className="back-icon"/> Τα νέα μας</Link>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    site {
      siteMetadata {
        disqus
      }
    }
    
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        date(formatString: "DD-MM-YYYY")
        title
        thumbnail
        img1
        img2
        img3
        img4
        img5
        img6
        img7
        img8
        img9
        img10
        img11
        img12
        img13
        img14
        img15
      }
    }

    allMarkdownRemark{
      edges{
        node{
          frontmatter{
            title
            path
            thumbnail
            img1
            img3
            img4
            img5
            img6
            img7
            img8
            img9
            img10
            img11
            img12
            img13
            img14
            img15
          }
        }
      }
    }
  }
`
